import {
  User,
} from '@root/domain/User'
import {
  apiService,
} from '@services/apiService'

const userApi = apiService.injectEndpoints({
  endpoints: builder => ({
    createUser: builder.mutation({
      query: ({
        uuid, ...data
      }) => ({
        url: `api/user/${uuid}`,
        method: 'POST',
        body: {
          ...data,
          _method: 'PUT',
        },
      }),
    }),
    updateUser: builder.mutation({
      query: ({
        uuid, ...data
      }) => ({
        url: `api/user/${uuid}`,
        method: 'PATCH',
        body: {
          ...data,
          _method: 'PATCH',
        },
      }),
    }),
    fetchUser: builder.query<User, string>({
      query: uuid => ({
        url: `api/user/${uuid}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useCreateUserMutation,
  useFetchUserQuery,
  useUpdateUserMutation,
} = userApi
