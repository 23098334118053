import {
  useRef,
} from 'react'
import {
  getErrorMessages, ErrorMessages, InputGroup, RadioBoxContainer, RadioBox,
} from '@selectra-it/selectra-ui'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useTranslation,
} from 'react-i18next'
import {
  useDispatch,
} from 'react-redux'

import {

  WizardFormData,
} from '@root/domain/Wizard'
import NavigationButtons from '@components/form/NavigationButtons'
import useEstimationWizardForm from '@hooks/useWizardForm'
import {
  setFormField,
} from '@root/services/formSlice'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  Gender,
} from '@root/domain/Gender'
import Icon from '@components/Icon'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

import useAdultStepHandlers from './useAdultStepHandlers'

const GenderInformation = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    form: {
      register,
      handleSubmit,
      formState: {
        errors,
        isDirty,
      },
      watch,
    },
  } = useEstimationWizardForm({ })

  const {
    id, backHandler, nextHandler,
  } = useAdultStepHandlers(wizardRoutes.PROFESSION, wizardRoutes.DENTAL_LEVEL, watch('adults').length)
  const dispatch = useDispatch()

  const errorType = errors.adults?.[id]?.gender?.type

  const status = useGetRadioStatus(errorType)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectInsuranceGender'),
  }, errorType)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  useSubmitFormOnChange(isDirty, watch(`adults.${id}.gender`), formRef)

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup errors={inputErrors}>
          <RadioBoxContainer>
            <RadioBox
              id="male"
              item={{
                icon: () => (
                  <Icon name='man' size={36}
                    className={'m-auto mb-2 block size-12'}
                  />
                ),
                label: t('wizard:wizard.steps.insuranceGender.items.men'),
                value: Gender.MALE,
              }}
              {...register(`adults.${id}.gender` as 'adults.0.gender', {
                required: true,
              })}
              status={status}
              dataCy={'man'}
            />
            <RadioBox
              id="female"
              item={{
                icon: () => (
                  <Icon name='woman' size={36}
                    className={'m-auto mb-2 block size-12'}
                  />
                ),
                label: t('wizard:wizard.steps.insuranceGender.items.women'),
                value: Gender.FEMALE,
              }}
              {...register(`adults.${id}.gender` as 'adults.0.gender', {
                required: true,
              })}
              status={status}
              dataCy={'woman'}
            />
          </RadioBoxContainer>

        </InputGroup>
      </div>
      <NavigationButtons onBackHandler={backHandler}/>
    </form>
  )
}

export default GenderInformation
