import {
  FC,
  useRef,
} from 'react'
import {
  InputGroup, getErrorMessages, ErrorMessages,
  RadioStacked,
  RadioStackedContainer,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  generatePath, useNavigate,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'

import {
  useGetLevelQuery,
} from '@services/level'
import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

const DentalLevel: FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    data: options,
  } = useGetLevelQuery()
  const {
    t,
  } = useTranslation(['wizard'])

  const {
    form: {
      register, watch,
      formState: {
        errors,
        isDirty,
      },
      handleSubmit,
    },
    onBackHandler,
  } = useEstimationWizardForm({})

  const errorType = errors.dentalLevel?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errorType)
  const dentalLevelStatus = useGetRadioStatus(errors.dentalLevel?.type)

  useSubmitFormOnChange(isDirty, watch('dentalLevel'), formRef)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField({
      ...data,
    }))

    const path = generatePath(wizardRoutes.INSURANCE_GENDER, {
      id: '0',
    })
    navigate(`/${path}`)
  }

  if (!options) return null

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <InputGroup
        errors={inputErrors}
      >
        <RadioStackedContainer>
          {options.map(({
            id,
            label,
            value,
          }) => (
            <RadioStacked
              key={id}
              id={id}
              item={{
                label,
                value,
                description: t(`wizard:wizard.steps.coverageLevel.${id}.dentalLevel` as 'wizard:wizard.steps.coverageLevel.MIN.dentalLevel'),
              }}
              status={dentalLevelStatus}
              {...register('dentalLevel', {
                required: true,
              })}
              dataCy={value}
            />
          ))}
        </RadioStackedContainer>
      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler}/>

    </form>
  )
}

export default DentalLevel
