import {
  ReactNode,
} from 'react'

import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

const messages: Record<wizardRoutes, (state: WizardFormData, pathname: string) => Array<string | ReactNode>> = {
  [wizardRoutes.BENEFICIARY]: () => [
    'Bienvenue sur notre comparateur ! <br /> Nous vous aiderons à trouver la <strong>meilleure mutuelle santé 2025</strong> et à réduire votre budget en seulement 5 minutes.',
    '<strong>Qui</strong> souhaitez-vous assurer ?'
  ],
  [wizardRoutes.INSURANCE_GENDER]: (state, pathname) => {
    const id = pathname.split('/')[2]
    const message = id === '1' ? 'Passons à votre conjoint(e), il/elle est :' : 'Commençons par votre profil, vous êtes :'

    return [message]
  },
  [wizardRoutes.BIRTH_DATE]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Sa <strong>date de naissance</strong> est le :' : 'Votre <strong>date de naissance</strong> est le :'

    return [message]
  },
  [wizardRoutes.PROFESSION]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Sa <strong>profession</strong> est :' : 'Votre <strong>profession</strong> est :'

    return [message]
  },
  [wizardRoutes.SOCIAL_REGIME]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Son <strong>régime social</strong> est :' : 'Votre <strong>régime social</strong> est :'

    return [message]
  },
  [wizardRoutes.CHILDREN]: () => [
    "Combien d'enfants avez-vous ?"
  ],
  [wizardRoutes.CHILDREN_BIRTH_DATE]: (state, pathname) => {
    interface Childs {
      [key: number]: string;
    }
    const childs:Childs = {
      0: 'premier',
      1: 'deuxième',
      2: 'troisième',
      3: 'quatrième',
      4: 'cinquième',
      5: 'sixième',
      6: 'septième',
      7: 'huitième',
      8: 'neuvième',
      9: 'dixième',
    }
    const hasMoreThanOneChild = parseInt(state.amountOfChildren) > 1
    const id = parseInt(pathname.split('/')[2]) ?? 0

    const message = !hasMoreThanOneChild ? 'La date de naissance de votre enfant est le :' : `La date de naissance de votre ${childs[id]} enfant est le :`

    return [message]
  },
  [wizardRoutes.POSTAL_CODE]: () => [
    'Quel est votre <strong>code postal</strong> ?'
  ],
  [wizardRoutes.INSURANCE_DATE]: () => [
    '<strong>Quand</strong> souhaitez-vous que votre contrat débute ?'
  ],
  [wizardRoutes.REGULAR_HEALTH_LEVEL]: () => [
    'Quelle couverture souhaitez-vous pour vos <strong>soins courants</strong> ?'
  ],
  [wizardRoutes.HOSPITAL_LEVEL]: () => [
    'Quelle couverture souhaitez-vous en cas d\'<strong>hospitalisation</strong> ?'
  ],
  [wizardRoutes.OPTICAL_LEVEL]: () => [
    'Quelle couverture souhaitez-vous pour vos <strong>soins optiques</strong> ?'
  ],
  [wizardRoutes.DENTAL_LEVEL]: () => [
    'Quelle couverture souhaitez-vous pour vos <strong>soins dentaires</strong> ?'
  ],
  [wizardRoutes.EMAIL]: () => [
    'Et pour finir, nous avons besoin de <strong>vos coordonnés</strong> pour obtenir vos devis personnalisés.',
    'Cela nous permettra de vous mettre en relation avec les assureurs et de vous aider en cas de besoin. 👌'
  ],
}

export default messages
