export const enum LeadsourceOption {
  COMPARATOR_HELP,
  COMPARATOR_HELP_CARD,
}

export const PhoneNumbers = {
  [LeadsourceOption.COMPARATOR_HELP]: '0182883179',
  [LeadsourceOption.COMPARATOR_HELP_CARD]: '0186652610',
} as const

export const Leadsource = {
  [LeadsourceOption.COMPARATOR_HELP]: 'fr-ii-ck-healthcomparatorbanner',
  [LeadsourceOption.COMPARATOR_HELP_CARD]: 'fr-ii-ck-healthcomparatorhelp',
} as const

export enum Owner {
  INSURANCE_FRANCE_MANAGER = '1499247000795478875',
}

export enum Name {
  CALLBACK = 'Callback Request FR Insurance Comparator',
}

export interface ImmediateMerCallbackDate {
  isImmediate: true,
  date: null,
}

export interface ScheduledMerCallbackDate {
  isImmediate: false,
  date: string,
}

export interface Interaction {
  lead_source: (typeof Leadsource)[keyof typeof Leadsource],
  name: Name,
  owner: Owner,
  email: string,
  origin_url: string,
  phone: string,
  callback_date?: ImmediateMerCallbackDate | ScheduledMerCallbackDate,
}

export interface Contact {
  firstName: string,
  lastName: string,
  owner: Owner,
  lead_source: (typeof Leadsource)[keyof typeof Leadsource]
  birthdate: string,
  address: string,
}

export interface Record {
  interaction?: Interaction,
  contact?: Contact,
}
