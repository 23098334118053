import {
  InputGroup, getErrorMessages, ErrorMessages, Datepicker,
  RadioStackedContainer,
  RadioStacked,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
  useEffect,
  useRef,
} from 'react'

import dayjs from '@root/plugins/day'
import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'

const InsuranceDate: FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const {
    form: {
      register, formState: {
        errors,
        isDirty,
      },
      setValue,
      watch,
      trigger,
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    revalidateMode: 'onChange',
  })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const status = useGetInputStatus(errors?.insuranceDate?.type)
  const contratDateRadioStatus = useGetRadioStatus(errors?.insuranceDateRadio?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addInsuranceDate'),
  }, errors?.insuranceDate?.type)

  const now = dayjs() // today's date
  const tomorrow = now.add(1, 'day') // tomorrow's date
  const todayValue = now.format('DD/MM/YYYY') // formatted as DD/MM/YYYY
  const tomorrowValue = tomorrow.format('DD/MM/YYYY')
  const todayFormatted = now.format('dddd D MMMM YYYY') // formatted as "day dayNumber month year"
  const tomorrowFormatted = tomorrow.format('dddd D MMMM YYYY')

  const inOneMonth = now.add(1, 'month').toDate()

  const insuranceDate = watch('insuranceDate')
  const insuranceDateRadio = watch('insuranceDateRadio')

  const items = [{
    id: 'today', label: [t('wizard:wizard.steps.insuranceDate.today'), todayFormatted], value: todayValue,
  }, {
    id: 'later', label: [t('wizard:wizard.steps.insuranceDate.tomorrow'), tomorrowFormatted], value: tomorrowValue,
  }]

  useEffect(() => {
    if (insuranceDate?.length > 0) {
      setValue('insuranceDateRadio', '')
      trigger('insuranceDateRadio')
    }
  }, [insuranceDate, setValue, trigger])

  useEffect(() => {
    if (insuranceDateRadio?.length > 0) {
      setValue('insuranceDate', '')
      trigger('insuranceDate')
    }
  }, [insuranceDateRadio, setValue, trigger])

  useSubmitFormOnChange(isDirty, insuranceDateRadio, formRef)

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
      ref={formRef}
    >
      <InputGroup
        errors={inputErrors}
        description={t('wizard:wizard.steps.insuranceDate.description')}
      >
        <RadioStackedContainer>
          {items.map(({
            id,
            label,
            value,
          }) => (
            <RadioStacked
              key={id}
              id={id}
              item={{
                label,
                value,
              }}
              status={contratDateRadioStatus}
              {...register('insuranceDateRadio', {
                validate: {
                  required: (value, {
                    insuranceDate,
                  }) => {
                    return value?.length > 0 || insuranceDate?.length > 0
                  },
                },
              })}
              dataCy={value}
            />
          ))}
        </RadioStackedContainer>

        <div className='py-2 text-center text-neutral-400'>
          ou
        </div>

        <Datepicker
          label={t('wizard:wizard.steps.insuranceDate.label')}
          maxDate={inOneMonth}
          status={status}
          minDate={now.add(2, 'day').toDate()}
          {...register('insuranceDate', {
            validate: {
              required: (value, {
                insuranceDateRadio,
              }) => {
                return value?.length > 0 || insuranceDateRadio?.length > 0
              },
            },
          })}
          dataCy="insurance-date"
        />

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler}/>
    </form>
  )
}

export default InsuranceDate
