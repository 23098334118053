import {
  INPUT_STATUS, MaskedInput,
} from '@selectra-it/selectra-ui'
import {
  ReactElement,
} from 'react'
import {
  FieldValues, Path, UseFormRegister,
} from 'react-hook-form'

import {
  useLazyValidatePhoneNumberQuery,
} from '@root/services/phone'

interface PhoneNumberInputProps<T extends FieldValues> {
  label: string,
  status: INPUT_STATUS,
  required?: boolean,
  name: Path<T>,
  register: UseFormRegister<T>
}

const PhoneNumberInput: <T extends FieldValues>(p: PhoneNumberInputProps<T>) => ReactElement = ({
  name,
  label,
  required = true,
  status,
  register,
}) => {
  const [trigger] = useLazyValidatePhoneNumberQuery()

  return (
    <MaskedInput
      mask={'99 99 99 99 99'}
      label={label}
      {...register(name, {
        required,
        minLength: 9,
        validate: {
          phoneNumber: async (value: string) => {
            try {
              await trigger({
                number: value,
                country: 'FR',
              })
              return true
            } catch (error) {
              return false
            }
          },
        },
      })}
      status={status}
      dataCy="phone-number"
    />
  )
}

export default PhoneNumberInput
