export enum wizardRoutes {
  BENEFICIARY = '',
  INSURANCE_GENDER = 'information-beneficiaire/:id/sexe',
  BIRTH_DATE = 'information-beneficiaire/:id/age',
  PROFESSION = 'information-beneficiaire/:id/profession',
  SOCIAL_REGIME = 'information-beneficiaire/:id/regime-social',
  CHILDREN = 'nombre-enfants',
  CHILDREN_BIRTH_DATE= 'nombre-enfants/:id/age-enfant',
  POSTAL_CODE = 'code-postal',
  INSURANCE_DATE = 'date-debut-contrat',
  REGULAR_HEALTH_LEVEL = 'besoin-soins-courants',
  HOSPITAL_LEVEL = 'besoin-hospitalisation',
  OPTICAL_LEVEL = 'besoin-optique',
  DENTAL_LEVEL = 'besoin-dentaire',
  EMAIL = 'mail',
}
