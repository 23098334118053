import {
  FunctionComponent, SVGProps,
} from 'react'

import Alptis from '@assets/insurers/alptis.svg?react'
import Heyme from '@assets/insurers/heyme.svg?react'
import April from '@assets/insurers/april.svg?react'
import Miltis from '@assets/insurers/miltis.svg?react'
import Meilleurtaux from '@assets/insurers/meilleurtaux.svg?react'
import Apicil from '@assets/insurers/apicil.png'
import Neoliane from '@assets/insurers/neoliane.svg?react'

import {
  Nullable,
} from './Wizard'

export interface PhoneNumberDetails {
  countryCode: number
  nationalNumber: string
  numberOfLeadingZeros: number
  countryCodeSource: number
}

export interface PhoneNumber {
  countryCode: string
  number: PhoneNumberDetails
}

export enum CustomerService {
  online_chat = 'online_chat',
  phone = 'phone',
}

export enum InsurerMerOption {
  SUBSCRIPTION = 'SUBSCRIPTION',
  CALLBACK_REQUEST = 'CALLBACK_REQUEST',
  CALLBACK_REQUEST_SELECTRA = 'CALLBACK_REQUEST_SELECTRA',
}

export type InsurerId = (
  '6578d964-6d33-4abf-875b-d1be65e0fbc1' |
  'd358ed16-9419-4801-836d-61772558d2e1' |
  '9218f071-739e-4741-80c7-786580388228' |
  'ca6df745-8b0e-4b1e-be76-13a158774392' |
  '8218abc6-3a22-4d03-98a5-a7d757b4d902' |
  'f63e4960-523d-45d2-b321-d62f4440b700' |
  'af35573a-cbd6-49b5-a5a7-d3c4131d6a07'
)

export interface Insurer {
  id: InsurerId
  name: string
  description: string
  administrationFee: number
  customerService: CustomerService
  leadSource?: string
  phoneNumber?: PhoneNumber
  healthOnlineSubscriptionEnabled: boolean
  homeOnlineSubscriptionEnabled: boolean
  oriasNumber?: string
  sirenNumber?: string
  slug: string
  redistributionRate?: number
  managementFees?: number
  activeHealthInsurer: boolean
  activeHomeInsurer: boolean
  address?: string
  postalCode?: string
  locality?: string
  isHealthInsurer: boolean
  isHomeInsurer: boolean
  merGatewayPrimary: InsurerMerOption
  merGatewaySecondary: Nullable<InsurerMerOption>
}

export const insurerLogos: Record<InsurerId, FunctionComponent<SVGProps<SVGSVGElement>> | string> = {
  '6578d964-6d33-4abf-875b-d1be65e0fbc1': Alptis,
  'd358ed16-9419-4801-836d-61772558d2e1': Heyme,
  '9218f071-739e-4741-80c7-786580388228': Miltis,
  'ca6df745-8b0e-4b1e-be76-13a158774392': April,
  '8218abc6-3a22-4d03-98a5-a7d757b4d902': Meilleurtaux,
  'f63e4960-523d-45d2-b321-d62f4440b700': Apicil,
  'af35573a-cbd6-49b5-a5a7-d3c4131d6a07': Neoliane,
}
