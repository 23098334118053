import {
  InsurancePerson,
  Children,
  PostalCode,
  InsuranceDate,
  RegularHealthLevel,
  HospitalLevel,
  OpticalHealthLevel,
  DentalLevel,
  Email,
  ChildrenBirthDate,
} from '@components/wizard/steps'
import {
  Step,
} from '@root/domain/Wizard'
import GenderInformation from '@components/wizard/steps/AdultInformation/GenderInformation'
import BirthDate from '@components/wizard/steps/AdultInformation/BirthDate'
import ProfessionInformation from '@components/wizard/steps/AdultInformation/ProfessionInformation'
import SocialRegimeInformation from '@components/wizard/steps/AdultInformation/SocialRegimeInformation'
import {
  Beneficiary,
} from '@root/domain/Beneficiary'

import {
  wizardRoutes,
} from './wizard-routes'

export enum StepperGroup {
  COVERAGE = 'COVERAGE',
  BENEFICIARIES = 'BENEFICIARIES',
  CONTACT_INFORMATION = 'CONTACT_INFORMATION',
}

export const steps: Step[] = [
  {
    name: 'insurancePerson',
    path: wizardRoutes.BENEFICIARY,
    shouldRender: () => true,
    element: () => <InsurancePerson />,
    group: StepperGroup.COVERAGE,
  },
  {
    name: 'InsuranceDate',
    path: wizardRoutes.INSURANCE_DATE,
    shouldRender: () => true,
    element: () => <InsuranceDate />,
    group: StepperGroup.COVERAGE,
  },
  {
    name: 'regularHealthLevel',
    path: wizardRoutes.REGULAR_HEALTH_LEVEL,
    shouldRender: () => true,
    element: () => <RegularHealthLevel />,
    group: StepperGroup.COVERAGE,
  },
  {
    name: 'hospitalLevel',
    path: wizardRoutes.HOSPITAL_LEVEL,
    shouldRender: () => true,
    element: () => <HospitalLevel />,
    group: StepperGroup.COVERAGE,
  },
  {
    name: 'opticalLevel',
    path: wizardRoutes.OPTICAL_LEVEL,
    shouldRender: () => true,
    element: () => <OpticalHealthLevel />,
    group: StepperGroup.COVERAGE,
  },
  {
    name: 'dentalLevel',
    path: wizardRoutes.DENTAL_LEVEL,
    shouldRender: () => true,
    element: () => <DentalLevel />,
    group: StepperGroup.COVERAGE,
  },
  {
    name: 'gender',
    path: wizardRoutes.INSURANCE_GENDER,
    shouldRender: () => true,
    element: () => (
      <GenderInformation />
    ),
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'profession',
    path: wizardRoutes.PROFESSION,
    shouldRender: () => true,
    element: () => (
      <ProfessionInformation />
    ),
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'socialRegime',
    path: wizardRoutes.SOCIAL_REGIME,
    shouldRender: () => true,
    element: () => (
      <SocialRegimeInformation />
    ),
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'birthDate',
    path: wizardRoutes.BIRTH_DATE,
    shouldRender: () => true,
    element: () => (
      <BirthDate />
    ),
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'PostalCode',
    path: wizardRoutes.POSTAL_CODE,
    shouldRender: () => true,
    element: () => <PostalCode />,
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'children',
    path: wizardRoutes.CHILDREN,
    shouldRender: state => state.beneficiary === Beneficiary.PRINCIPAL_AND_CHILDREN || state.beneficiary === Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN,
    element: () => <Children />,
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'childrenBirthDate',
    shouldRender: state => parseInt(state.amountOfChildren, 10) > 0,
    path: 'nombre-enfants/:id/age-enfant',
    element: () => <ChildrenBirthDate />,
    group: StepperGroup.BENEFICIARIES,
  },
  {
    name: 'email',
    path: wizardRoutes.EMAIL,
    shouldRender: () => true,
    element: () => <Email />,
    group: StepperGroup.CONTACT_INFORMATION,
  }
]

export const routes = [
  ...steps.map(({
    path, element: Element, children,
  }) => ({
    path,
    element: <Element />,
    children: children?.map(({
      path, element: Element,
    }) => ({
      path,
      element: <Element />,
    })),
  }))
]
